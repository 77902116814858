<template>
  <div class="overflow-hidden">
    <div
      style="height: 95vh !important"
      class="text-center d-flex flex-1 justify-content-center align-items-center"
      v-if="IsLoading"
    >
      <b-spinner variant="success" type="grow"></b-spinner>
    </div>
    <div v-if="collections.length === 0 && !IsLoading" class="nodataimg">
      <img :src="noData" id="no-data" />
      <h1>You don't have favorite account</h1>
    </div>
    <div v-if="collections.length > 0">
      <b-modal
        id="modal-warning-for-edit-collection"
        title="Edit Collection"
        centered
        hide-footer
        modal-class="modal-warning"
        ok-title="Edit Collection"
        ref="refEditCollectionModal"
      >
        <div class="d-flex">
          <b-form-input placeholder="Collection Name" v-model="collectionName">
          </b-form-input>
          <b-button
            class="ml-2"
            variant="warning"
            v-b-tooltip.hover.top
            title="Edit Collection"
            @click="editCollection"
            style="padding: 5px 10px"
          >
            <feather-icon icon="EditIcon" size="18" />
          </b-button>
        </div>
      </b-modal>
      <b-modal
        id="modal-danger-for-delete-acc"
        ok-variant="danger"
        ok-title="Confirm"
        modal-class="modal-danger"
        centered
        title="Removing from favorite"
        @ok="removeAccountFromFavorite"
      >
        <b-card-text>
          Are you sure you want to remove this account from your favorites?
        </b-card-text>
      </b-modal>
      <b-modal
        id="modal-danger-for-delete-col"
        ok-variant="danger"
        ok-title="Confirm"
        modal-class="modal-danger"
        centered
        title="Delete"
        @ok="deleteCollection"
      >
        <b-card-text>
          Are you sure you want to delete this collection ?
        </b-card-text>
      </b-modal>
      <b-card v-for="collection in collections" :key="collection.id" no-body>
        <b-card-header header-tag="header" class="p-1" role="tab">
          <div
            class="flex-grow-1 d-flex align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <feather-icon icon="FolderIcon" size="20" />
              <span class="ml-2">
                {{ collection.name }}
              </span>
            </div>
            <div>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-success"
                class="btn btn-icon rounded-circle"
                v-b-tooltip.hover.left
                :class="collection.isCollapsed ? null : 'collapsed'"
                :aria-expanded="collection.isCollapsed ? 'true' : 'false'"
                aria-controls="collapse-4"
                :title="
                  collection.isCollapsed ? 'hide collection' : 'show collection'
                "
                @click="collection.isCollapsed = !collection.isCollapsed"
              >
                <feather-icon
                  :icon="collection.isCollapsed ? 'EyeOffIcon' : 'EyeIcon'"
                  size="20"
                />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-warning"
                class="btn btn-icon rounded-circle"
                v-b-tooltip.hover.top
                title="Edit Collection"
                v-if="collection.name != 'Default'"
                @click="editItemPopupCol(collection)"
              >
                <feather-icon icon="EditIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-danger"
                class="btn btn-icon rounded-circle"
                v-b-tooltip.hover.right
                title="Delete Collection"
                v-if="collection.name != 'Default'"
                @click="removeItemPopupCol(collection.id)"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
            </div>
          </div>
        </b-card-header>
        <div v-show="false">
          {{ addIndexToTable(collection.favorite_accounts) }}
        </div>
        <b-collapse id="collapse-4" v-model="collection.isCollapsed">
          <b-card-body>
            <b-table
              striped
              hover
              :items="collection.favorite_accounts"
              :fields="fields"
              responsive
            >
              <template #cell(index)="data">
                {{ data.value }}
              </template>
              <template slot="name" slot-scope="data">
                {{ data.item.name }}
              </template>
              <template #cell(actions)="data">
                <router-link
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-danger"
                  class="btn btn-icon rounded-circle"
                  v-b-tooltip.hover.right
                  title="Search by Account"
                  :to="{
                    name: 'market-manager-accounts-search',
                    query: {
                      search: isNaN(data.item.devID)
                        ? data.item.name
                        : data.item.devID,
                    },
                  }"
                  target="_blank"
                >
                  <feather-icon icon="SearchIcon" size="20" />
                </router-link>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-danger"
                  class="btn btn-icon rounded-circle"
                  v-b-tooltip.hover.left
                  title="remove from favorite"
                  @click="removeItemPopupAcc(data.item)"
                >
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
              </template>
            </b-table>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { removeLocalStorageItems } from "@/helper/helpers-funcs";
import noData from "@/assets/images/NoData.png";
import {
  BTable,
  BAvatar,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BPagination,
  BButton,
  BModal,
  VBModal,
  BCardText,
  BContainer,
  BFormSelect,
  BCardHeader,
  BCardBody,
  BCollapse,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormInput,
    BCardHeader,
    BCardBody,
    BCollapse,
    BTable,
    BFormSelect,
    BFormGroup,
    BAvatar,
    VBTooltip,
    BCard,
    BButtonGroup,
    BRow,
    BCol,
    BPagination,
    BButton,
    BModal,
    VBModal,
    BCardText,
    BContainer,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      noData,
      collections: [],
      id: null,
      fields: [
        {
          key: "index",
          label: "#",
          sortable: false,
          class: "text-center",
        },
        {
          key: "name",
          label: "Account Name",
          class: "text-center",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "text-center",
        },
      ],
      collectionName: null,
      IsLoading: true,
      userId: null,
    };
  },

  created() {
    this.userId = JSON.parse(localStorage.getItem("accessToken"));
    this.fetchCollections();
  },
  methods: {
    removeItemPopupAcc(item) {
      this.id = item.id;
      this.$root.$emit("bv::show::modal", "modal-danger-for-delete-acc");
    },
    removeItemPopupCol(id) {
      this.id = id;
      this.$root.$emit("bv::show::modal", "modal-danger-for-delete-col");
    },
    editItemPopupCol(item) {
      this.id = item.id;
      this.collectionName = item.name;
      this.$root.$emit("bv::show::modal", "modal-warning-for-edit-collection");
    },
    fetchCollections() {
      axios
        .post("/all-accounts-in-favorite", { userId: this.userId })
        .then(({ data }) => {
          this.collections = data.collections.map((collection) => ({
            ...collection,
            isCollapsed: true,
          }));
          if (this.collections.length === 0 || !this.collections.find(x=> x.name === "Default")) {
            this.collections.push({
              id: 1,
              name: "Default",
              user_id: 1,
              favorite_accounts: [
              ],
              isCollapsed: true,
            });
          }

          this.IsLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    removeAccountFromFavorite() {
      axios
        .post("/remove-accounts-from-favorite", {
          id: this.id,
        })
        .then(({ data }) => {
          if (data.status === "success") {
            this.showToast("success", "StarIcon", "Success", data.message);
            this.fetchCollections();
          } else {
            this.showToast("danger", "StarIcon", "Error", data.message);
          }
        });
    },
    editCollection() {
      axios
        .post("/edit-collection", {
          id: this.id,
          name: this.collectionName,
        })
        .then(({ data }) => {
          if (data.status === "success") {
            this.showToast("success", "StarIcon", "Success", data.message);
            this.$refs.refEditCollectionModal.hide();
            this.fetchCollections();
          } else {
            this.showToast("danger", "StarIcon", "Error", data.message);
          }
        });
    },
    deleteCollection() {
      axios
        .post("/delete-collection", {
          id: this.id,
        })
        .then(({ data }) => {
          if (data.status === "success") {
            this.showToast("success", "StarIcon", "Success", data.message);
            this.fetchCollections();
          } else {
            this.showToast("danger", "StarIcon", "Error", data.message);
          }
        });
    },
    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;
        return item;
      });

      return t;
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    const token = JSON.parse(localStorage.getItem("accessToken"));
    axios
      .post("/check-user-login", {
        token,
      })
      .then(({ data }) => {
        if (data.status === "success") {
          next(true);
        } else {
          removeLocalStorageItems();
          next({ name: "auth-login" });
        }
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#no-data {
  height: 100%;
  max-height: 800px;
  max-width: 400px;
  width: 100%;
}
.nodataimg {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
